import chroma from "chroma-js";
import _ from "lodash";

import csiro from "../../../csiro.module.scss";

export const fixed = [
  csiro["midday-blue"],
  csiro["fushsia"],
  csiro["gold"],
  csiro["lavender"],
  csiro["orange"],
  csiro["plum"],
  csiro["light-mint"],
  csiro["forest"],
  csiro["light-teal"],
  csiro["midnight-blue"],
];

export const generateScaleColors = (count: number) => {
  const shadeCount = _.ceil(count / fixed.length);
  const colors: string[] = [];
  _.times(shadeCount, (i) => {
    _.each(fixed, (color) => {
      colors.push(
        chroma(color || "black")
          .mix("white", i / shadeCount, "lch")
          .css()
      );
    });
  });
  return _.take(colors, count);
};
