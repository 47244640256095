import "./date-range-input.scss";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { DateRange as RdpDateRange, DayPicker } from "react-day-picker";

import { Dropdown } from "../dropdown";

const dateFormat = "dd MMM yy";

type DateRange = [string, string];

interface DateRangeInputProps {
  /**
   * DateRangeInput value as array with first entry being start date and last being end date.
   */
  value: DateRange;
  /**
   * Change handler that receives same format as value prop.
   */
  onChange: (value: DateRange) => void;
  /**
   * Optional minimum date that can be selected.
   */
  min?: string;
  /**
   * Optional maximum date that can be selected.
   */
  max?: string;
  /**
   * Flag to disable the input.
   */
  disabled?: boolean;
  /**
   * Optional label override, defaults to "Date".
   */
  label?: string;
}

/**
 * Controlled input for selecting a date range.
 */
export const DateRangeInput = ({
  value,
  onChange,
  min,
  max,
  label = "Date",
  disabled = false,
}: DateRangeInputProps) => {
  const from = DateTime.fromISO(value[0]);
  const to = DateTime.fromISO(value[1]);
  const initialState = {
    from: from.toJSDate(),
    to: to.toJSDate(),
  };
  const [draft, setDraft] = useState<RdpDateRange | undefined>(initialState);
  const isValid =
    _.isDate(_.get(draft, "from")) && _.isDate(_.get(draft, "to"));
  return (
    <Dropdown
      label={label}
      text={`${from.toFormat(dateFormat)} - ${to.toFormat(dateFormat)}`}
      icon={faCalendar}
      disabled={disabled}
      id="date-range"
      onExited={() => {
        if (isValid) {
          onChange([
            DateTime.fromJSDate(draft!.from as Date).toISODate()!,
            DateTime.fromJSDate(draft!.to as Date).toISODate()!,
          ]);
        } else {
          setDraft(initialState);
        }
      }}
    >
      <div
        className={clsx("p-3 border", {
          "border-danger": !isValid,
          "border-white": isValid,
        })}
      >
        <DayPicker
          className="easi-day-picker"
          disabled={disabled}
          mode="range"
          selected={draft}
          onSelect={setDraft}
          hidden={(date) => {
            if (max && date > DateTime.fromISO(max).toJSDate()) {
              return true;
            }
            if (min && date < DateTime.fromISO(min).toJSDate()) {
              return true;
            }
            return false;
          }}
          defaultMonth={DateTime.fromISO(value[0]).toJSDate()}
          required={false}
        />
        <div className="d-flex justify-content-end gap-2 pt-3">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setDraft(initialState)}
            disabled={_.isEqual(draft, initialState)}
          >
            Reset
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setDraft(undefined)}
            disabled={_.isUndefined(draft)}
          >
            Clear
          </Button>
        </div>
        {!isValid && (
          <>
            <hr />
            <p className="text-danger text-center form-text pb-0 mb-0">
              Date range required
            </p>
          </>
        )}
      </div>
    </Dropdown>
  );
};
